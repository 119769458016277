:root {

	--peer-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	--peer-border: 2px solid #323543;
	--peer-empty-avatar: url('./images/buddy.svg');
	--peer-video-bg-color: rgba(0, 0, 0, 0.75);

	--selected-peer-border-color: rgba(55, 126, 255, 1.0);

	--primary-color: #04aac3;
	--primary-light-color: #95f2ff;
	--primary-dark-color: #096775;
	--accent-color: #E47927;
	--white-color: #fff;
	--dark-color: #1C1F2E;
	--dark-light-color: #242736;
	--dark-active-color: #2E313E;
	--dark-secondary-color: #191B28;
	--warning-color: #FFBC1F;
	--danger-color: #FF6262;
	--text-primary-color: #EDEEEF;
	--text-secondary-color: #76777C;
	--background-color: var(--dark-color);
	--active-speaker-border-color: rgba(149, 242, 255, 0.65);
	--peer-bg-color: var(--dark-color);
}

html
{
	height: 100%;
	width: 100%;
	font-family: 'Roboto';
	font-weight: 400;
	margin : 0;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body
{
	height: 100%;
	width: 100%;
	font-size: 16px;
	margin: 0;
}

#cazomeet
{
	height: 100%;
	width: 100%;
}

.join-page {
	background: #1c1f2e;
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	@media (min-width: 768px) {
	  position: absolute;
	}
	.navbar-brand {
	  font-weight: bold;
	  letter-spacing: 2px;
	  color: #4e4e4e;
	  @media (min-width: 768px) {
		font-size: 1.8rem;
	  }
	  i {
		color: #248eff;
		font-size: 0.5rem;
	  }
	}
	.navbar {
	  &.scrolled {
		@media (min-width: 768px) {
		  position: fixed;
		  left: 0;
		  right: 0;
		  animation: scrolled 0.3s ease forwards;
		}
	  }
	  
	}
	.nav-center-content{
	  margin-left: 30px;
	  display: flex;
	  justify-content: center;
	  align-self: center;
	}
	.nav-link {
	  text-transform: uppercase;
	  font-weight: 700;
	  color: #4e4e4e !important;
	  font-size: 0.85rem;
	  &:hover {
		color: darken(#248eff, 6%) !important;
	  }
	  &.active {
		color: lighten(#248eff, 5%) !important;
	  }
	  @media (min-width: 768px) {
		padding: 1rem 1rem;
	  }
	}
	.top-bar {
	  background-color: #2f4e72;
	  display: none;
	  @media (min-width: 768px) {
		display: block;
	  }
	}

	.nav-icon {
	  width: 30px;
	  height: 25px;
	  position: relative;
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	  -webkit-transition: 0.5s ease-in-out;
	  -moz-transition: 0.5s ease-in-out;
	  -o-transition: 0.5s ease-in-out;
	  transition: 0.5s ease-in-out;
	  cursor: pointer;
	  span {
		display: block;
		position: absolute;
		height: 3px;
		width: 100%;
		background: #4e4e4e;
		border-radius: 9px;
		opacity: 1;
		left: 0;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: 0.25s ease-in-out;
		-moz-transition: 0.25s ease-in-out;
		-o-transition: 0.25s ease-in-out;
		transition: 0.25s ease-in-out;
		&:nth-child(1) {
		  top: 0px;
		}
		&:nth-child(2) {
		  top: 9px;
		}
		&:nth-child(3) {
		  top: 18px;
		}
	  }
	  &.open {
		span {
		  &:nth-child(1) {
			top: 9px;
			-webkit-transform: rotate(135deg);
			-moz-transform: rotate(135deg);
			-o-transform: rotate(135deg);
			transform: rotate(135deg);
		  }
		  &:nth-child(2) {
			opacity: 0;
			left: -60px;
		  }
		  &:nth-child(3) {
			top: 9px;
			-webkit-transform: rotate(-135deg);
			-moz-transform: rotate(-135deg);
			-o-transform: rotate(-135deg);
			transform: rotate(-135deg);
		  }
		}
	  }
	}
}

div.MuiToolbar-gutters {
	padding-right: 0;
}
 
div.room-container {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	&__left-side {
		overflow: hidden;
		width: 100%;
		display: flex;
		position: relative;
		flex-direction: column;
		transition: padding 0.4s;
		.room-view-container {
			height: 100%;
			background-color: var(--dark-secondary-color);
			margin: 0 16px;
			border-radius: 12px;
			overflow: hidden;
			position: relative;
		}
		.room-toolbar-container {
			display: flex;
			height: 80px;
			margin: 0 24px;
			padding-left: 8px;
			overflow: auto;
			button.MuiIconButton-root {
				border-radius: 6px;
				background: var(--dark-light-color);
				width: 48px;
				height: 48px;
				border: 1px solid #323543;
				margin-right: 12px;
				.MuiTouchRipple-root {
					border-radius: 6px;
				}
				&.MuiIconButton-colorSecondary {
					color: var(--danger-color);
				}
				&.MuiIconButton-colorPrimary {
					color: var(--text-primary-color);
				}
				&.ghost-button {
					background: none;
					border: none;
				}
				&.ghost-button-actived {
					color: var(--primary-color);
				}
				&.MuiIconButton-root.Mui-disabled {
					color: rgba(255, 255, 255, 0.3);
					background-color: transparent;
				}
			}
			&__left-side {
				display: flex;
				align-items: center;
				width: 100%;
			}
			&__center {
				display: flex;
				align-items: center;
				justify-content: center;
				& > button {
					min-width: 120px;
					height: 48px;
					font-weight: 500;
				}
			}
			&__right-side {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: flex-end;
				button {
					margin-right: 0 !important;
					margin-left: 12px;
					&.ghost-button {
						margin-left: 4px;
					}
				}
			}
			
		}
		.room-view-pin-user {
			position: absolute;
			left: 8px;
			top: 8px;
			background: rgba(0, 0, 0, 0.25);
			border-radius: 8px;
			padding: 2px 10px 2px 6px;
			z-index: 1;
			
			&__icon {
				font-size: 16px;
			}
			&__text {
				padding-left: 4px;
				color: #b0b3b8;
				font-size: 12px;
				font-weight: 500;
			}
		}
		.room-view-recording {
			position: absolute;
			left: 12px;
			top: 8px;
			background: rgba(0, 0, 0, 0.25);
			border-radius: 8px;
			padding: 3px 10px 3px 6px;
			z-index: 1;
			
			&__icon {
				font-size: 16px;
				animation-name: mwaBlink;
				animation-duration: 1.4s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
			}
			&__text {
				padding-left: 4px;
				color: #b0b3b8;
				font-size: 13px;
				font-weight: 500;
			}
		}

		@keyframes mwaBlink{
			0%{
				opacity: 0;
			}
			50%{
				opacity: 1;
			}
			100%{
				opacity: 0;
			}
		}
	}

	&__right-side {
		width: 527px;
		flex-shrink: 0;
		transition: 0.4s;
		@media screen and (max-width: 1023px) {
			width: 0 !important;
		}
	}
}

div.meeting-right-side-container {
	min-width: 527px;
	background-color: var(--dark-color);
	header.MuiPaper-root {
		display: flex;
		box-shadow: none;
		height: 64px;
		background: var(--dark-color);
		align-items: center;
		button {
			height: 64px;
			color: var(--text-primary-color);
			.MuiBadge-root {
				color: var(--text-primary-color);
			}
		}
	}
	> div.MuiPaper-elevation1 {
		background: var(--dark-color);
		box-shadow: none;
		border-radius: 0;
		> .MuiPaper-root {
			background: var(--dark-color);
			box-shadow: none;
			min-height: 80px;
			> .MuiInputBase-root {
				background: #191b28;
				margin-left: 0;
				border-radius: 6px;
				color: #fff;
			}
		}
	}
}

.filmstrip-grid-item {
	margin: 0 4px;
}

.join-page {
	.MuiButton-containedPrimary {
		color: #fff;
		max-width: 400px;
	}
	.MuiFormControl-fullWidth {
		max-width: 400px;
	}
	#joinButton {
		margin-top: 16px;
		height: 48px;
	}
}

@media (max-width: 480px) {
	div.room-container {
		&__left-side {
			.room-view-container {
				margin: 0 4px;
			}
			.room-toolbar-container {
				margin: 0 4px;
				&__left-side {
					order: 2;
				}
				&__center {
					order: 1;
					padding-right: 12px;
				}
				&__right-side {
					order: 3;
				}
			}
		}
		
	}
}

button.Peer-smallContainer-132:hover {
	background-color: #096775;
}

.democratic-vertical-mode {
	> * {
		margin: 0 !important;
	}
}

.join-landing {
	background: #1c1f2e;
	min-height: 100vh;
	.join-landing-header {
		height: 64px;
		display: flex;
		align-items: center;
		padding: 0 24px;
		position: relative;
		&__logo {
			height: 32px;
		}
		&__link {
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
	.join-landing-content {
		max-width: 528px;
		width: 100%;
    margin: 0 auto;
		margin-top: 32px;
		.join-landing-title {
			color: #fff;
			font-weight: 500;
			font-size: 32px;
			text-align: center;
			margin-bottom: 8px;
		}
		.join-landing-description {
			font-weight: 500;
			font-size: 16px;
			text-align: center;
			margin-bottom: 32px;
		}

		.join-landing-preview-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.video-preview-wrapper {
				width: 100%;
				height: 297px;
				border-radius: 5px;
				overflow: hidden;
				background: #000;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				.video-preview {
					width: 100%;
					height: 100%;
				}
				.video-preview-disabled-label {
					font-weight: 500;
					font-size: 20px;
				}
				.audio-preview-disabled-label {
					font-weight: 500;
					font-size: 14px;
					border-radius: 20px;
					padding: 4px 16px;
					background: rgba(0, 0, 0, 0.5);
					position: absolute;
					top: 16px;
					left: 50%;
					transform: translateX(-50%);
					color: #fff;
				}
			}
			.video-preview-actions {
				display: flex;
				width: 100%;
				margin-top: 8px;
			}
			button.MuiIconButton-root {
				border-radius: 6px;
				background: var(--dark-light-color);
				width: 48px;
				height: 48px;
				border: 1px solid #323543;
				margin-right: 12px;
				.MuiTouchRipple-root {
					border-radius: 6px;
				}
				&.MuiIconButton-colorSecondary {
					color: var(--danger-color);
				}
				&.MuiIconButton-colorPrimary {
					color: var(--text-primary-color);
				}
				&.ghost-button {
					background: none;
					border: none;
				}
				&.ghost-button-actived {
					color: var(--primary-color);
				}
			}
		}
	}
	.join-landing-footer {
		max-width: 528px;
		width: 100%;
    	margin: 0 auto;
		.join-button-container {
			text-align: right;
			margin-top: 100px;
			display: flex;
			&__ready-label {
				margin-right: 24px;
				font-weight: 500;
				font-size: 16px;
				line-height: 42px;
				flex-shrink: 0;	
			}
			&__join-btn {
				height: 42px;
				min-width: 200px;
				background: #037182;
				color: #fff;
				flex-shrink: 0;
			}
			&__back-btn {
				height: 42px;
				background: var(--dark-light-color);
				color: #fff;
				flex-shrink: 0;
				box-shadow: none;
			}
		}
	}
}

.add-participant-content {
	.add-participant-link {
		display: flex;
		align-items: center;
		.add-participant-link-icon {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #404244;
			flex-shrink: 0;
		}
		.add-participant-link-content {
			padding-left: 8px;
			overflow: hidden;
			&__link {
				font-weight: 500;
				font-size: 15px;
				display: flex;
				position: relative;
				white-space: nowrap;
			}
			&__privacy {
				.MuiSelect-root {
					font-weight: 500;
					color: #b0b3b8;
					font-size: 13px;
					padding-top: 0;
					padding-bottom: 0;
				}
				.MuiInput-underline:before, .MuiInput-underline:after {
					display: none;
				}
				.MuiSelect-select:focus {
					background: transparent;
				}
			}
		}
		.add-participant-copy-btn {
			margin-left: 16px;
			.MuiSvgIcon-root {
				width: 16px;
				height: 16px;
			}
		}
	}
}

.request-join-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	
	&__spinner {

	}
	&__title {
		font-weight: 500;
		font-size: 18px;
		margin-top: 16px;
	}
	&__description {

	}
}

.room-container.live-stream-room {
	.room-view-container {
		margin: 0;
		border-radius: 0;
		> .me-producer {
			margin: 0 !important;
			border: none !important;
			height: 100%;
			.peer-infos {
				display: none;
			}
		}
	}
	.live-label {
		position: absolute;
		left: 8px;
		top: 8px;
		background: #fb4e4e;
		border-radius: 5px;
		padding: 2px 10px 2px 10px;
		z-index: 1;
		color: #fff;
		font-size: 12px;
		font-weight: 500;
		text-transform: uppercase;
		animation-name: liveLabelBlink;
		animation-duration: 1.4s;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
		line-height: 18px;
	}

	@keyframes liveLabelBlink {
		0% {
		  opacity: 0.6;
		}
		50% {
		  opacity: 1;
		}
		100% {
		  opacity: 0.6;
		}
	  }
}